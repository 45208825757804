html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.modal-mode .ant-modal-body,
.modal-mode .ant-modal-title,
.modal-mode p {
    font-size: 24px
}

.modal-mode .ant-modal-close-icon {
    font-size: 24px
}

.modal-mode .ant-modal-header {
    height: 65px;
}

.modal-mode .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-mode button {
    font-size: 22px;
    height: 54px!important;
}

.modal-mode button.ant-modal-close {
    top: 0!important;
    right: 0!important;
    width: 54px!important;
}

.custom-sider-item, .power-off-button {
    margin: 0
}